import React, { useState, useEffect, useCallback, Fragment } from "react";
import "./Main.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import {
	getAllAdmins,
	getAdminDataForDateRange,
} from "../services/user-requests";
import "./Main.css";

const AdminActivityTable = ({ activeUser }) => {
	const [tableType, setTableType] = useState("daily_total");
	const [admins, setAdmins] = useState([]);
	const [dateRangedAdmins, setDateRangedAdmins] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [adminEmailToggle, setAdminEmailToggle] = useState(false);
	const [dailyApprovalCIDToggle, setDailyApprovalCIDToggle] = useState(false);
	const [dailyApprovalNoCIDToggle, setDailyApprovalNoCIDToggle] = useState(
		false
	);
	const [dailyRejectToggle, setDailyRejectToggle] = useState(false);
	const [dailyBanToggle, setDailyBanToggle] = useState(false);
	const [totalApprovalCIDToggle, setTotalApprovalCIDToggle] = useState(false);
	const [totalApprovalNoCIDToggle, setTotalApprovalNoCIDToggle] = useState(
		false
	);
	const [totalRejectToggle, setTotalRejectToggle] = useState(false);
	const [totalBanToggle, setTotalBanToggle] = useState(false);

	useEffect(() => {
		console.log("getting admins");
		getAllAdmins().then((rows) => {
			console.log(rows);
			setAdmins(rows);
		});
	}, []);

	const sortByCriteria = (rows, criteria, toggle, toggleFn, type) => {
		var compare;
		if (!type) {
			compare = (a, b) => {
				if (a[criteria] < b[criteria]) {
					if (!toggle) {
						return -1;
					} else {
						return 1;
					}
				}
				if (a[criteria] > b[criteria]) {
					if (!toggle) {
						return 1;
					} else {
						return -1;
					}
				}
				// a must be equal to b
				return 0;
			};
		}

		const sortedRows = rows.sort(compare);
		toggleFn(!toggle);
		setAdmins(sortedRows);
	};
	const [selectionRanges, setSelectionRanges] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	]);
	const handleSelect = (ranges) => {
		console.log(ranges);
		setSelectionRanges([ranges.selection]);
	};
	const handleDateRangePick = () => {
		getAdminDataForDateRange(
			selectionRanges[0].startDate,
			selectionRanges[0].endDate
		)
			.then((res) => {
				console.log(res);
				setDateRangedAdmins(combineAdminData(res.data));
				setTableType("date_range");
				setStartDate(selectionRanges[0].startDate);
				setEndDate(selectionRanges[0].endDate);
			})
			.catch((err) => console.log(err));
	};
	const combineAdminData = (rows) => {
		const dataByAdmin = {};
		console.log(rows);
		for (let row of rows) {
			if (dataByAdmin[row.admin_email]) {
				dataByAdmin[row.admin_email].approval_cids += row.approval_cids;
				dataByAdmin[row.admin_email].approvals += row.approvals;
				dataByAdmin[row.admin_email].rejects += row.rejects;
			} else {
				dataByAdmin[row.admin_email] = row;
			}
		}
		console.log(dataByAdmin);
		console.log(Array.from(Object.values(dataByAdmin)));
		return Array.from(Object.values(dataByAdmin));
	};
	if (activeUser.status !== "Super Admin") {
		return (
			<div className="main-container">
				You have not been approved for this content. Contact the admin
				for more information.
			</div>
		);
	}
	return (
		<div className="main-container">
			<div className="admin-header">
				<div className="header-container">
					<div className="title">
						<h2>Admin Activity Table</h2>
					</div>
				</div>
				<div className="header-container">
					<DateRange
						showDateDisplay={false}
						ranges={selectionRanges}
						onChange={handleSelect}
					/>
				</div>
				<div className="header-container">
					<Button onClick={handleDateRangePick} variant="primary">
						View data for selected range
					</Button>
				</div>
				<div className="header-container">
					<div className="title">
						<h3>
							{tableType === "daily_total"
								? "Viewing daily and total data"
								: `Viewing data for range ${startDate.getMonth() +
										1}/${startDate.getDate()}/${startDate.getFullYear()} - ${endDate.getMonth() +
										1}/${endDate.getDate()}/${endDate.getFullYear()}`}
						</h3>
					</div>
				</div>
			</div>
			<Card style={{ width: "100%" }}>
				<Card.Body>
					<Table
						style={{ width: "100%" }}
						striped
						bordered
						hover
						responsive
					>
						<thead>
							<tr>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											admins,
											"email",
											adminEmailToggle,
											setAdminEmailToggle
										)
									}
								>
									Admin
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											admins,
											"daily_approval_cids",
											dailyApprovalCIDToggle,
											setDailyApprovalCIDToggle
										)
									}
								>
									{tableType === "daily_total"
										? "Daily Approvals for CID"
										: "Approvals for CID"}
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											admins,
											"daily_approvals",
											dailyApprovalNoCIDToggle,
											setDailyApprovalNoCIDToggle
										)
									}
								>
									{tableType === "daily_total"
										? "Daily Approvals (no CID)"
										: "Approvals (no CID)"}
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											admins,
											"daily_rejects",
											dailyRejectToggle,
											setDailyRejectToggle
										)
									}
								>
									{tableType === "daily_total"
										? "Daily Rejections"
										: "Rejections"}
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											admins,
											"daily_bans",
											dailyBanToggle,
											setDailyBanToggle
										)
									}
								>
									{tableType === "daily_total"
										? "Daily Bans"
										: "Bans"}
								</th>
								{tableType === "daily_total" && (
									<Fragment>
										<th
											className="pointer"
											onClick={() =>
												sortByCriteria(
													admins,
													"total_approval_cids",
													totalApprovalCIDToggle,
													setTotalApprovalCIDToggle
												)
											}
										>
											Total Approvals for CID
										</th>
										<th
											className="pointer"
											onClick={() =>
												sortByCriteria(
													admins,
													"total_approvals",
													totalApprovalNoCIDToggle,
													setTotalApprovalNoCIDToggle
												)
											}
										>
											Total Approvals (no CID)
										</th>
										<th
											className="pointer"
											onClick={() =>
												sortByCriteria(
													admins,
													"total_rejects",
													totalRejectToggle,
													setTotalRejectToggle
												)
											}
										>
											Total Rejections
										</th>
										<th
											className="pointer"
											onClick={() =>
												sortByCriteria(
													admins,
													"total_bans",
													totalBanToggle,
													setTotalBanToggle
												)
											}
										>
											Total Bans
										</th>
									</Fragment>
								)}
							</tr>
						</thead>
						<tbody>
							{tableType === "daily_total"
								? admins.map((admin, i) => {
										return (
											<tr key={i}>
												<td>{admin.email}</td>
												<td>
													{admin.daily_approval_cids}
												</td>
												<td>{admin.daily_approvals}</td>
												<td>{admin.daily_rejects}</td>
												<td>{admin.daily_bans}</td>
												<td>
													{admin.total_approval_cids}
												</td>
												<td>{admin.total_approvals}</td>
												<td>{admin.total_rejects}</td>
												<td>{admin.total_bans}</td>
											</tr>
										);
								  })
								: dateRangedAdmins.map((admin, i) => {
										return (
											<tr key={i}>
												<td>{admin.admin_email}</td>
												<td>{admin.approval_cids}</td>
												<td>{admin.approvals}</td>
												<td>{admin.rejects}</td>
												<td>{admin.bans}</td>
											</tr>
										);
								  })}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</div>
	);
};

export default AdminActivityTable;
