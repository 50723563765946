import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import { sendUserCreateEmail } from "../services/auth-requests";
import { useAlert } from "react-alert";

const AddUserModal = ({ modalIsOpen, closeModal, fetchUsers }) => {
	const handleSubmit = () => {};
	const alert = useAlert();

	return (
		<Modal show={modalIsOpen} onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>Add a User</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={{
						email: "",
					}}
					validate={(values) => {
						let errors = {};
						if (!values.email) {
							errors.email = "Required";
						}
						return errors;
					}}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={(values, { setSubmitting }) => {
						sendUserCreateEmail(values.email).then((res) => {
							console.log(res);
							closeModal();
							fetchUsers();
							if (res.error) {
								alert.error(res.error);
							} else if (res.message) {
								alert.success(res.message);
							}
						});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							<div className="add-user-modal">
								<Form.Group controlId="formBasicEmail">
									<Form.Control
										className="auth-text-input"
										type="text"
										name="email"
										value={values.email}
										onChange={handleChange}
										isInvalid={!!errors.email}
										placeholder="Enter email"
									/>
									<Form.Control.Feedback type="invalid">
										{errors.email}
									</Form.Control.Feedback>
								</Form.Group>
								<Button
									variant="primary"
									type="submit"
									disabled={isSubmitting}
								>
									Submit
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	);
};
export default AddUserModal;
