import React, { Fragment, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ReactTooltip from "react-tooltip";
import { Video } from "cloudinary-react";

import "./Main.css";

const SubmissionRow = ({
	submission,
	queue,
	approveSub,
	rejectSub,
	undoSub,
	publishSub,
	updateAdminTitle,
	updateAssetID,
	updateAssetID2,
	updateVideoUrl,
	banUser,
	showModal,
	publishSubmission,
	checkedSubmissions,
	setCheckedSubmissions,
	isMouseDown,
	setIsMouseDown,
	index,
	setCheckedSubStart,
	setCheckedSubEnd,
	setIsAdding,
}) => {
	const [showSubMenu, setShowSubMenu] = useState(false);
	const [isPublished, setIsPublished] = useState(submission.is_published);
	const [isCID, setIsCID] = useState(submission.status === "approved_cid");
	const [isEditingAdminTitle, setIsEditingAdminTitle] = useState(false);
	const [adminTitle, setAdminTitle] = useState(
		submission.admin_title ? submission.admin_title : submission.title
	);
	const [assetID, setAssetID] = useState(
		submission.asset_id ? submission.asset_id : ""
	);
	const [assetID2, setAssetID2] = useState(
		submission.asset_id_2 ? submission.asset_id_2 : ""
	);
	const [isEditingAssetID, setIsEditingAssetID] = useState(false);
	const [isEditingAssetID2, setIsEditingAssetID2] = useState(false);
	const [videoUrl, setVideoUrl] = useState(
		submission.video_url ? submission.video_url : ""
	);
	const [isEditingVideoUrl, setIsEditingVideoUrl] = useState(false);
	const [currAssignedAdmin, setCurrAssignedAdmin] = useState(
		submission.assigned_admin ? submission.assigned_admin : "None"
	);
	const [isChecked, setIsChecked] = useState(false);
	const [isRejecting, setIsRejecting] = useState(false);
	const [rejectNotes, setRejectNotes] = useState("");
	useEffect(() => {
		setIsPublished(submission.is_published);
		setIsCID(submission.status === "approved_cid");
		setIsEditingAdminTitle(false);
		setAdminTitle(
			submission.admin_title ? submission.admin_title : submission.title
		);
		setAssetID(submission.asset_id ? submission.asset_id : "");
		setIsEditingAssetID(false);
		setVideoUrl(submission.video_url ? submission.video_url : "");
		setIsEditingVideoUrl(false);
		setCurrAssignedAdmin(
			submission.assigned_admin ? submission.assigned_admin : "None"
		);
	}, [submission]);
	return (
		<Fragment>
			<tr style={{ cursor: "pointer" }}>
				{queue === "pending" ? (
					<td
						className="x-half"
						onMouseDown={(e) => {
							e.preventDefault();
							setIsMouseDown(true);
							setIsAdding(
								!checkedSubmissions.includes(submission.sub_id)
							);
							setCheckedSubStart(index);
						}}
						onMouseOver={(e) => {
							e.preventDefault();
							if (isMouseDown) {
								setCheckedSubEnd(index);
							}
						}}
						onClick={() => {
							if (
								checkedSubmissions.includes(submission.sub_id)
							) {
								setCheckedSubmissions((subs) =>
									subs.filter(
										(sub) => sub !== submission.sub_id
									)
								);
							} else {
								setCheckedSubmissions((subs) =>
									subs.concat(submission.sub_id)
								);
							}
						}}
					>
						<input
							type="checkbox"
							// checked={isChecked}
							// onChange={() => setIsChecked((c) => !c)}
							checked={checkedSubmissions.includes(
								submission.sub_id
							)}
						/>
					</td>
				) : null}
				<td onClick={() => setShowSubMenu(!showSubMenu)}>
					{submission.sub_email}
				</td>
				<td onClick={() => setShowSubMenu(!showSubMenu)}>
					{submission.title}
				</td>
				{queue === "approved" || queue === "approved_cid" ? (
					<td style={{ position: "relative" }}>
						{isEditingAdminTitle ? (
							<Fragment>
								<input
									value={adminTitle}
									style={{
										width: "100%",
										marginRight: "1rem",
										padding: ".25rem .75rem",
										marginBottom: ".5rem",
									}}
									onChange={(e) =>
										setAdminTitle(e.target.value)
									}
								/>
								<Button
									onClick={() => {
										setIsEditingAdminTitle(false);
										updateAdminTitle(
											submission.sub_id,
											adminTitle
										);
									}}
								>
									Save
								</Button>
							</Fragment>
						) : (
							<span
								onClick={() => setIsEditingAdminTitle(true)}
								style={{
									position: "absolute",
									top: "0",
									left: "0",
									right: "0",
									bottom: "0",
									width: "100%",
									height: "100%",
									padding: "12px",
								}}
							>
								{adminTitle}
							</span>
						)}
					</td>
				) : null}
				<td className="x-half">
					<div
						className="view-link"
						onClick={() => showModal("video", submission)}
					>
						<p data-tip data-for={`thumbnail-${submission.sub_id}`}>
							View
						</p>
					</div>
				</td>

				{queue === "approved" || queue === "approved_cid" ? (
					<td className="submission-btn-container">
						{isPublished ? (
							<Dropdown>
								<Dropdown.Toggle
									variant="success"
									id="dropdown-basic"
								>
									Yes
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => {
											setIsPublished(false);
											publishSubmission(
												submission.sub_id,
												false
											);
										}}
									>
										No
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						) : (
							<Dropdown>
								<Dropdown.Toggle
									variant="danger"
									id="dropdown-basic"
								>
									No
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => {
											setIsPublished(true);
											publishSubmission(
												submission.sub_id,
												true
											);
										}}
									>
										Yes
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)}
					</td>
				) : null}
				{queue === "approved" || queue === "approved_cid" ? (
					<td style={{ position: "relative" }}>
						{isEditingAssetID ? (
							<Fragment>
								<input
									value={assetID}
									style={{
										width: "100%",
										marginRight: "1rem",
										padding: ".25rem .75rem",
										marginBottom: ".5rem",
									}}
									onChange={(e) => setAssetID(e.target.value)}
								/>
								<div className="flex">
									<Button
										onClick={() => {
											setIsEditingAssetID(false);
											updateAssetID(
												submission.sub_id,
												assetID
											);
										}}
									>
										Save
									</Button>
									<Button
										variant="danger"
										onClick={() => {
											setIsEditingAssetID(false);
										}}
									>
										Cancel
									</Button>
								</div>
							</Fragment>
						) : (
							<div onClick={() => setIsEditingAssetID(true)}>
								Public Facing: {assetID}
							</div>
						)}
						{isEditingAssetID2 ? (
							<Fragment>
								<input
									value={assetID2}
									style={{
										width: "100%",
										marginRight: "1rem",
										padding: ".25rem .75rem",
										marginBottom: ".5rem",
									}}
									onChange={(e) =>
										setAssetID2(e.target.value)
									}
								/>
								<div className="flex">
									<Button
										onClick={() => {
											setIsEditingAssetID2(false);
											updateAssetID2(
												submission.sub_id,
												assetID2
											);
										}}
									>
										Save
									</Button>
									<Button
										variant="danger"
										onClick={() => {
											setIsEditingAssetID2(false);
										}}
									>
										Cancel
									</Button>
								</div>
							</Fragment>
						) : (
							<div onClick={() => setIsEditingAssetID2(true)}>
								Internal: {assetID2}
							</div>
						)}
					</td>
				) : null}
				{queue === "approved" || queue === "approved_cid" ? (
					<td style={{ position: "relative" }}>
						{isEditingVideoUrl ? (
							<Fragment>
								<input
									value={videoUrl}
									style={{
										width: "100%",
										marginRight: "1rem",
										padding: ".25rem .75rem",
										marginBottom: ".5rem",
									}}
									onChange={(e) =>
										setVideoUrl(e.target.value)
									}
								/>
								<Button
									onClick={() => {
										setIsEditingVideoUrl(false);
										updateVideoUrl(
											submission.sub_id,
											videoUrl
										);
									}}
								>
									Save
								</Button>
							</Fragment>
						) : (
							<span
								onClick={() => setIsEditingVideoUrl(true)}
								style={{
									position: "absolute",
									top: "0",
									left: "0",
									right: "0",
									bottom: "0",
									width: "100%",
									height: "100%",
									padding: "12px",
								}}
							>
								{videoUrl}
							</span>
						)}
					</td>
				) : null}
				<td
					className={
						queue === "pending" ? "submission-btn-container x3" : ""
					}
				>
					{queue === "pending" ? (
						isRejecting ? (
							<div className="flex">
								<input
									value={rejectNotes}
									style={{
										width: "100%",
										marginRight: "1rem",
										padding: ".25rem .75rem",
										marginBottom: ".5rem",
									}}
									onChange={(e) =>
										setRejectNotes(e.target.value)
									}
									placeholder="Reject Notes"
								/>
								<Button
									onClick={async () => {
										await rejectSub(
											submission.sub_id,
											submission.sub_email,
											rejectNotes
										);
										setIsRejecting(false);
									}}
								>
									Save
								</Button>
								<Button
									variant="danger"
									onClick={() => setIsRejecting(false)}
								>
									Cancel
								</Button>
							</div>
						) : (
							<Fragment>
								<Button
									variant="success"
									onClick={() => {
										approveSub(
											submission.sub_id,
											submission.yoon_id,
											true
										);
									}}
								>
									Approve for CID
								</Button>
								<Button
									variant="success"
									onClick={() => {
										approveSub(
											submission.sub_id,
											submission.yoon_id,
											false
										);
									}}
								>
									Approve (no CID)
								</Button>
								<Button
									variant="warning"
									onClick={() => setIsRejecting(true)}
								>
									Reject
								</Button>
								<Button
									variant="danger"
									onClick={() => {
										banUser(submission.sub_email);
									}}
								>
									Ban User
								</Button>
							</Fragment>
						)
					) : (
						<Button
							onClick={() => {
								undoSub(
									submission.sub_id,
									submission.yoon_id,
									queue === "approved" ||
										queue === "approved_cid"
										? "UnApprove"
										: "Undo"
								);
							}}
						>
							{queue === "approved" || queue === "approved_cid"
								? "UnApprove"
								: "Undo"}
						</Button>
					)}
				</td>
				{queue === "approved" || queue === "approved_cid" ? (
					<td style={{ minWidth: "10rem" }}>
						<div onClick={() => showModal("tag", submission)}>
							Current:{" "}
							{submission.tags
								? submission.tags.join(", ")
								: "No Tags"}
							<br />
							<span className="view-link">Edit Tags</span>
						</div>
					</td>
				) : null}
				{queue === "rejected" ? (
					<td>{submission.rejection_text}</td>
				) : null}
				<td onClick={() => setShowSubMenu(!showSubMenu)}>
					{submission.referrer}
				</td>
				{queue === "approved" || queue === "approved_cid" ? (
					<td className="submission-btn-container">
						{isCID ? (
							<Dropdown>
								<Dropdown.Toggle
									variant="success"
									id="dropdown-basic"
								>
									Yes
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => {
											setIsCID(false);
											approveSub(
												submission.sub_id,
												submission.yoon_id,
												false,
												true
											);
										}}
									>
										No
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						) : (
							<Dropdown>
								<Dropdown.Toggle
									variant="danger"
									id="dropdown-basic"
								>
									No
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => {
											setIsCID(true);
											approveSub(
												submission.sub_id,
												submission.yoon_id,
												true,
												true
											);
										}}
									>
										Yes
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)}
					</td>
				) : null}
				{queue === "pending" ? (
					<td>{currAssignedAdmin ? currAssignedAdmin : "None"}</td>
				) : null}
			</tr>
			{showSubMenu ? (
				<tr className="sub-menu">
					<td
						colSpan={
							queue === "approved" || queue === "approved_cid"
								? "16"
								: "10"
						}
					>
						<span>Clip ID: {submission.sub_id}</span>
						<br />
						<span>User ID: {submission.yoon_id}</span>
						<br />
						<span>
							Submission Time: {submission.sub_time.slice(0, 10)},{" "}
							{submission.sub_time.slice(11, 19)}
						</span>
						<br />
						{queue === "approved" || queue === "approved_cid" ? (
							<>
								<span>
									Approval Date: {submission.approval_date}
								</span>
								<br />
							</>
						) : null}
						<span>Submitter Name: {submission.submitter_name}</span>
						<br />
						<span>
							Submitter Alt. Email: {submission.sub_alt_email}
						</span>
						<br />
						<span>Submitter Phone: {submission.phone}</span>
						<br />
						<span>Submitter Country: {submission.country}</span>
						<br />
						{submission.channel_id && submission.channel_title ? (
							<span>
								Submitter YouTube Channel:{" "}
								<a
									href={`https://youtube.com/channel/${submission.channel_id}`}
								>
									{submission.channel_title}
								</a>
							</span>
						) : null}
						<br />
						<span>Video Description:</span>
						<br />
						<span>{submission.description}</span>
						<br />
					</td>
				</tr>
			) : null}
			<ReactTooltip id={`thumbnail-${submission.sub_id}`}>
				<Video
					publicId={`${submission.cloudinary_id}.jpg`}
					resourceType="video"
					cloudName="yoon-digital"
					width="100"
				></Video>
			</ReactTooltip>
		</Fragment>
	);
};

export default SubmissionRow;
