import React, { useState, useEffect, Fragment } from "react";
import { Redirect } from "react-router-dom";
import "./Main.css";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {
	getSubmissions,
	getSubmissionsByQueue,
	approveSubmission,
	rejectSubmission,
	undoSubmission,
	rejectUserSubs,
	publishSubmission,
	updateAdminTitle,
	updateAssetID,
	updateVideoUrl,
	approveSubmissionCID,
	assignAdmin,
} from "../services/submission-requests";
import {
	getTags,
	getActiveTags,
	updateSubTags,
} from "../services/tag-requests";
import {
	banFromYoon,
	updateApprovedSubCount,
	getAdmins,
} from "../services/user-requests";
import AllAssetsSubmissionRow from "./AllAssetsSubmissionRow";
import { Video, Transformation } from "cloudinary-react";
import cloudinary from "cloudinary-core";
import { useAlert } from "react-alert";

const AllAssetsQueue = ({ activeUser, queue }) => {
	const [submissions, setSubmissions] = useState([]);
	const [relevantSubs, setRelevantSubs] = useState([]);
	const [cloudinaryId, setCloudinaryId] = useState("");
	const [videoFileName, setVideoFileName] = useState("");
	const [activeSubmission, setActiveSubmission] = useState("");
	const [submissionBeingViewed, setSubmissionBeingViewed] = useState({});
	const [activeTags, setActiveTags] = useState([]);
	const [allTags, setAllTags] = useState([]);
	const [modalType, setModalType] = useState("");
	const [csvState, setCSVState] = useState("");
	const [csvData, setCSVData] = useState([]);
	const [assignedAdminToggle, setAssignedAdminToggle] = useState(false);
	const [assignedAdminSelectToggle, setAssignedAdminSelectToggle] =
		useState(false);
	const [currAssignedAdmin, setCurrAssignedAdmin] = useState("None");
	const [checkedSubmissions, setCheckedSubmissions] = useState([]);
	const [checkedSubStart, setCheckedSubStart] = useState(null);
	const [checkedSubEnd, setCheckedSubEnd] = useState(null);
	const [isAdding, setIsAdding] = useState(null);
	const [emailToggle, setEmailToggle] = useState(false);
	const [clipIDToggle, setClipIDToggle] = useState(false);
	const [userIDToggle, setUserIDToggle] = useState(false);
	const [referrerToggle, setReferrerToggle] = useState(false);
	const [titleToggle, setTitleToggle] = useState(false);
	const [adminTitleToggle, setAdminTitleToggle] = useState(false);
	const [subTimeToggle, setSubTimeToggle] = useState(false);
	const [approvalDateToggle, setApprovalDateToggle] = useState(false);
	const [amountPerPage, setAmountPerPage] = useState(60);
	const [numPages, setNumPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const cloudinaryCore = new cloudinary.Cloudinary({
		cloud_name: "yoon-digital",
	});
	const [assignedAdmins, setAssignedAdmins] = useState([]);
	const alert = useAlert();
	useEffect(() => {
		getAdmins().then((data) => {
			setAssignedAdmins(data);
		});
	}, []);
	useEffect(() => {
		getSubmissions().then((rows) => {
			console.log(rows);
			setSubmissions(rows);
			setRelevantSubs(
				rows.sort((a, b) => {
					if (a.sub_id > b.sub_id) {
						return -1;
					}
					if (b.sub_id < a.sub_id) {
						return 1;
					}
					return 0;
				})
			);
		});
	}, [assignedAdminSelectToggle]);
	useEffect(() => {
		if (amountPerPage === "All") {
			setNumPages([1]);
		} else {
			const maxNum = Math.ceil(submissions.length / amountPerPage);
			let numArr = [];
			for (let i = 1; i <= maxNum; i++) {
				numArr.push(i);
			}
			setNumPages(numArr);
		}
	}, [relevantSubs]);
	useEffect(() => {
		if (amountPerPage !== "All") {
			const startIdx = amountPerPage * (activePage - 1);
			const endIdx = amountPerPage * activePage;
			setRelevantSubs(submissions.slice(startIdx, endIdx));
		} else {
			setRelevantSubs(submissions);
		}
	}, [amountPerPage, activePage]);
	const handleFilterChange = (e) => {
		const emailFilter = e.target.value;
		const filteredSubs = submissions.filter((sub) =>
			sub.sub_email.toLowerCase().includes(emailFilter.toLowerCase())
		);
		setRelevantSubs(filteredSubs);
	};
	const handleChannelFilterChange = (e) => {
		const channelFilter = e.target.value;
		const filteredSubs = submissions.filter(
			(sub) =>
				sub.channel_title &&
				sub.channel_title
					.toLowerCase()
					.includes(channelFilter.toLowerCase())
		);
		setRelevantSubs(filteredSubs);
	};
	const handleTitleFilterChange = (e) => {
		const titleFilter = e.target.value;
		const filteredSubs = submissions.filter(
			(sub) =>
				(sub.title &&
					sub.title
						.toLowerCase()
						.includes(titleFilter.toLowerCase())) ||
				(sub.admin_title &&
					sub.admin_title
						.toLowerCase()
						.includes(titleFilter.toLowerCase()))
		);
		setRelevantSubs(filteredSubs);
	};
	const handleClipIdFilterChange = (e) => {
		const clipIdFilter = e.target.value;
		const filteredSubs = submissions.filter(
			(sub) => sub.sub_id && `${sub.sub_id}`.includes(clipIdFilter)
		);
		setRelevantSubs(filteredSubs);
	};
	const handleAssetIdFilterChange = (e) => {
		const assetIdFilter = e.target.value;
		const filteredSubs = submissions.filter(
			(sub) =>
				sub.asset_id &&
				sub.asset_id.toLowerCase().includes(assetIdFilter.toLowerCase())
		);
		setRelevantSubs(filteredSubs);
	};
	const approveSub = async (id, yoon_id, is_cid, has_approved) => {
		console.log("approve");
		if (!has_approved) {
			await updateApprovedSubCount(yoon_id, "increment");
		}
		var approveFn = is_cid ? approveSubmissionCID : approveSubmission;
		approveFn(id, activeUser.user_id).then((res) => {
			alert.success(
				`Submission Approved ${is_cid ? "for CID!" : "(no CID)"}`
			);
			const filteredSubs = submissions.filter((sub) => sub.sub_id !== id);
			setSubmissions(filteredSubs);
			setRelevantSubs(filteredSubs);
		});
	};
	const rejectSub = async (id, email) => {
		console.log("reject");
		rejectSubmission(id, email, activeUser.user_id).then((res) => {
			alert.error("Submission Rejected!");
			const filteredSubs = submissions.filter((sub) => sub.sub_id !== id);
			setSubmissions(filteredSubs);
			setRelevantSubs(filteredSubs);
		});
	};
	const undoSub = async (id, yoon_id, action) => {
		console.log(action, "undoSub");
		if (action === "Undo") {
			await updateApprovedSubCount(yoon_id, "decrement");
		}
		undoSubmission(id).then((res) => {
			alert.success("Submission sent back to pending");
			const filteredSubs = submissions.filter((sub) => sub.sub_id !== id);
			setSubmissions(filteredSubs);
			setRelevantSubs(filteredSubs);
		});
	};
	const banUser = async (email) => {
		window.confirm("Are you sure?");
		await rejectUserSubs(email, activeUser.user_id);
		await banFromYoon(email);
		window.location.href = "/";
	};
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const closeModal = () => setModalIsOpen(false);
	const showModal = (type, sub) => {
		setModalType(type);
		if (type === "tag") {
			setActiveSubmission(sub.sub_id);
			setActiveTags(sub.tags ? sub.tags : []);
			fetchTags();
			fetchActiveTags(sub.sub_id);
		} else {
			setSubmissionBeingViewed(sub);
			setVideoFileName(sub.file_name);
			setCloudinaryId(sub.cloudinary_id);
		}
		setModalIsOpen(true);
	};

	const fetchTags = () => {
		getTags().then((rows) => setAllTags(rows));
	};
	const fetchActiveTags = (id) => {
		getActiveTags(id).then((tags) => {
			console.log(tags);
			setActiveTags(tags[0].tags ? tags[0].tags : []);
		});
	};
	const updateSubTagsFn = async (name, operation) => {
		var correctTags;
		if (operation === "add") {
			correctTags = activeTags.concat(name);
		} else {
			correctTags = activeTags.filter((tag) => tag !== name);
		}
		console.log(correctTags);
		setActiveTags(correctTags);
		await updateSubTags(correctTags, activeSubmission);
	};

	const exportToCSV = (rows) => {
		setCSVState("loading");
		const headerRow = [
			"Clip ID",
			"User ID",
			"Email",
			"Title",
			"Admin Title",
			"Tags",
			"Submission Time",
			"Published",
			"Asset ID",
			"Video URL",
		];
		const rowsCSV = rows.map((row) => {
			return [
				row.sub_id,
				row.yoon_id,
				row.sub_email,
				row.title,
				row.admin_title,
				`"${row.tags}"`,
				`"${row.sub_time}"`,
				row.is_published,
				row.asset_id,
				row.video_url,
			];
		});
		rowsCSV.unshift(headerRow);
		const csvString = rowsCSV.map((row) => row.join(",")).join("\n");
		setCSVState("ready");
		var download = document.getElementById("csv-download");
		download.setAttribute(
			"href",
			"data:text/csv;charset=utf-8," + encodeURIComponent(csvString)
		);
		download.setAttribute("download", "user_table.csv");
	};
	const sortByCriteria = (rows, criteria, toggle, toggleFn, type) => {
		var compare;
		if (!type) {
			compare = (a, b) => {
				if (criteria === "assigned_admin") {
					if (!a[criteria] || a[criteria] === "None") {
						return 1;
					}
					if (!b[criteria] || b[criteria] === "None") {
						return -1;
					}
				}
				if (a[criteria] < b[criteria]) {
					if (!toggle) {
						return -1;
					} else {
						return 1;
					}
				}
				if (a[criteria] > b[criteria]) {
					if (!toggle) {
						return 1;
					} else {
						return -1;
					}
				}
				// a must be equal to b
				return 0;
			};
		}
		console.log(rows);
		const sortedRows = rows.sort(compare);
		console.log(sortedRows);
		toggleFn(!toggle);
		setSubmissions(sortedRows);
		setRelevantSubs(sortedRows);
	};
	const [isMouseDown, setIsMouseDown] = useState(false);
	useEffect(() => {
		window.addEventListener("mouseup", () => {
			setIsMouseDown(false);
		});
	}, []);
	useEffect(() => {
		console.log(!isMouseDown, checkedSubStart, checkedSubEnd);
		if (
			!isMouseDown &&
			(checkedSubStart || checkedSubStart === 0) &&
			(checkedSubEnd || checkedSubEnd === 0)
		) {
			setCheckedSubmissions((checkedSubs) => {
				const startIndex =
					checkedSubStart > checkedSubEnd
						? checkedSubEnd
						: checkedSubStart;
				const endIndex =
					checkedSubStart > checkedSubEnd
						? checkedSubStart + 1
						: checkedSubEnd + 1;
				if (isAdding) {
					return Array.from(
						new Set(
							checkedSubs.concat(
								submissions
									.slice(startIndex, endIndex)
									.map((sub) => sub.sub_id)
							)
						)
					);
				} else {
					const range = submissions
						.slice(startIndex, endIndex)
						.map((sub) => sub.sub_id);
					return Array.from(
						new Set(
							checkedSubs.filter((sub) => !range.includes(sub))
						)
					);
				}
			});
			setCheckedSubStart(null);
			setCheckedSubEnd(null);
		}
	}, [checkedSubStart, checkedSubEnd, isMouseDown]);
	if (activeUser.status === "Unapproved") {
		return (
			<div className="main-container">
				You have not been approved for this content. Contact the admin
				for more information.
			</div>
		);
	}
	return (
		<div className="main-container">
			<div className="header-container">
				<div className="title">
					<h2 style={{ whiteSpace: "no-wrap" }}>All Assets</h2>
				</div>
				<div className="filters">
					<div className="flex items-center">
						<div className="pages">
							<span className="label">Pages</span>
							<span
								className={`page-num${
									activePage === 1 ? " active" : ""
								}`}
								onClick={() => setActivePage(1)}
							>
								‹‹
							</span>
							<span
								className="page-num"
								onClick={() =>
									setActivePage((prevState) =>
										prevState - 1 > 0
											? prevState - 1
											: prevState
									)
								}
							>
								‹
							</span>
							{activePage - 1 > 0 && (
								<span
									className={`page-num`}
									onClick={() =>
										setActivePage(
											(prevState) => prevState - 1
										)
									}
								>
									{activePage - 1}
								</span>
							)}
							<span className={`page-num active`}>
								{activePage}
							</span>
							{activePage + 1 < numPages.length && (
								<span
									className={`page-num`}
									onClick={() =>
										setActivePage(
											(prevState) => prevState + 1
										)
									}
								>
									{activePage + 1}
								</span>
							)}
							<span
								className={`page-num${
									activePage === 1 ? " active" : ""
								}`}
								onClick={() =>
									setActivePage((prevState) =>
										prevState + 1 < numPages.length
											? prevState + 1
											: prevState
									)
								}
							>
								›
							</span>
							<span
								className="page-num"
								onClick={() => setActivePage(numPages.length)}
							>
								››
							</span>
						</div>
						<Dropdown>
							<Dropdown.Toggle
								variant="secondary"
								id="dropdown-basic"
							>
								{amountPerPage}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() => setAmountPerPage(30)}
								>
									30
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => setAmountPerPage(60)}
								>
									60
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => setAmountPerPage("All")}
								>
									All
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<span style={{ marginLeft: ".5rem" }}>per page</span>
					</div>
					<div className="flex flex-wrap">
						<input
							id="email-filter"
							placeholder="Search by Email"
							onChange={handleFilterChange}
						/>
						<input
							id="title-filter"
							placeholder="Search by Title"
							onChange={handleTitleFilterChange}
						/>
						<input
							id="channel-filter"
							placeholder="Search by YT Channel"
							onChange={handleChannelFilterChange}
						/>
						<input
							id="clip-id-filter"
							placeholder="Search by Clip ID"
							onChange={handleClipIdFilterChange}
						/>
						<input
							id="asset-id-filter"
							placeholder="Search by Asset ID"
							onChange={handleAssetIdFilterChange}
						/>
					</div>
				</div>
			</div>
			<div className="assign-admin">
				<label>Assign Admin</label>
				<Dropdown>
					<Dropdown.Toggle variant="secondary" id="dropdown-basic">
						{currAssignedAdmin}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								setCurrAssignedAdmin("None");
								assignAdmin(checkedSubmissions, "None").then(
									(data) => {
										alert.success("Successfully assigned");
										setAssignedAdminSelectToggle(
											!assignedAdminSelectToggle
										);
										setCheckedSubmissions([]);
									}
								);
							}}
						>
							None
						</Dropdown.Item>
						{assignedAdmins.map((admin, i) => (
							<Dropdown.Item
								key={i}
								onClick={() => {
									setCurrAssignedAdmin(admin);
									assignAdmin(checkedSubmissions, admin).then(
										(data) => {
											alert.success(
												"Successfully assigned"
											);
											setAssignedAdminSelectToggle(
												!assignedAdminSelectToggle
											);
											setCheckedSubmissions([]);
										}
									);
								}}
							>
								{admin}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			</div>
			<Card style={{ width: "100%" }}>
				<Card.Body>
					<Table
						style={{ width: "100%" }}
						striped
						bordered
						responsive
						className="fixed-header-table"
					>
						<thead>
							<tr>
								<th className="x-half">Select</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											submissions,
											"sub_email",
											emailToggle,
											setEmailToggle
										)
									}
								>
									Email
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											submissions,
											"title",
											titleToggle,
											setTitleToggle
										)
									}
								>
									Title
								</th>
								<th
									className="pointer"
									style={{ minWidth: "230px" }}
									onClick={() =>
										sortByCriteria(
											submissions,
											"admin_title",
											adminTitleToggle,
											setAdminTitleToggle
										)
									}
								>
									Admin Title
								</th>
								<th className="x-half">View</th>
								<th style={{ textAlign: "center" }}>
									Published?
								</th>
								<th style={{ minWidth: "230px" }}>Asset ID</th>
								<th style={{ minWidth: "230px" }}>Video URL</th>

								<th
									className="x3"
									style={{ textAlign: "center" }}
								>
									Actions
								</th>
								<th>Tags</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											submissions,
											"referrer",
											referrerToggle,
											setReferrerToggle
										)
									}
								>
									Referrer
								</th>
								<th style={{ minWidth: "230px" }}>
									Approved for CID?
								</th>
								<th
									className="pointer"
									style={{ minWidth: "230px" }}
									onClick={() =>
										sortByCriteria(
											submissions,
											"assigned_admin",
											assignedAdminToggle,
											setAssignedAdminToggle
										)
									}
								>
									Assigned Admin
								</th>
							</tr>
						</thead>
						<tbody>
							{relevantSubs.map((submission, i) => {
								return (
									<AllAssetsSubmissionRow
										submission={submission}
										queue={queue}
										key={i}
										approveSub={approveSub}
										rejectSub={rejectSub}
										undoSub={undoSub}
										banUser={banUser}
										showModal={showModal}
										publishSubmission={publishSubmission}
										updateAdminTitle={updateAdminTitle}
										updateVideoUrl={updateVideoUrl}
										updateAssetID={updateAssetID}
										checkedSubmissions={checkedSubmissions}
										setCheckedSubmissions={
											setCheckedSubmissions
										}
										isMouseDown={isMouseDown}
										setIsMouseDown={setIsMouseDown}
										index={i}
										setCheckedSubEnd={setCheckedSubEnd}
										setCheckedSubStart={setCheckedSubStart}
										setIsAdding={setIsAdding}
									/>
								);
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			<Modal show={modalIsOpen} onHide={closeModal}>
				{modalType === "tag" ? (
					<Fragment>
						<Modal.Header closeButton>
							<Modal.Title>Video Tags</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<h6>Current Tags</h6>
							<div className="tag-container">
								{activeTags
									? activeTags.map((tag, i) => {
											if (!tag) {
												return null;
											}
											return (
												<div
													className="btn btn-primary tag"
													key={i}
												>
													<span className="name">
														{tag}
													</span>
													<span
														onClick={() =>
															updateSubTagsFn(
																tag,
																"delete"
															)
														}
														className="close"
													>
														&times;
													</span>
												</div>
											);
									  })
									: null}
							</div>
							<h6>Add Tags</h6>
							<div className="add-tags">
								<Dropdown>
									<Dropdown.Toggle
										variant="secondary"
										id="dropdown-basic"
									>
										Add Tags
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{allTags
											.filter(
												(tag) =>
													!activeTags.includes(
														tag.name
													)
											)
											.map((tag, i) => {
												return (
													<Dropdown.Item
														key={i}
														eventKey={i}
														onClick={() =>
															updateSubTagsFn(
																tag.name,
																"add"
															)
														}
													>
														{tag.name}
													</Dropdown.Item>
												);
											})}
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={closeModal}>
								Close
							</Button>
						</Modal.Footer>
					</Fragment>
				) : (
					<Fragment>
						<Modal.Header closeButton>
							<Modal.Title>{videoFileName}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Video
								cloudName="yoon-digital"
								publicId={cloudinaryId}
								width="460"
								controls
								loop
							>
								<Transformation quality="auto" />
							</Video>
							<div style={{ margin: "1rem 0" }}>
								<a
									href={cloudinaryCore.video_url(
										cloudinaryId
									)}
									target="_blank"
									download
								>
									View / Download Original File
								</a>
								<a
									href={cloudinaryCore
										.video_url(cloudinaryId, {
											format: "gif",
											video_sampling: "100",
										})
										.replace("vs_100", "vs_100/e_loop")}
									target="_blank"
									download
									style={{ margin: "0 1.5rem" }}
								>
									Download GIF
								</a>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="success"
								onClick={async () => {
									await approveSub(
										submissionBeingViewed.sub_id,
										submissionBeingViewed.yoon_id,
										true
									);
									closeModal();
								}}
							>
								Approve for CID
							</Button>
							<Button
								variant="success"
								onClick={async () => {
									await approveSub(
										submissionBeingViewed.sub_id,
										submissionBeingViewed.yoon_id,
										false
									);
									closeModal();
								}}
							>
								Approve (no CID)
							</Button>
							<Button
								variant="warning"
								onClick={async () => {
									await rejectSub(
										submissionBeingViewed.sub_id,
										submissionBeingViewed.sub_email
									);
									closeModal();
								}}
							>
								Reject
							</Button>
							<Button
								variant="danger"
								onClick={async () => {
									banUser(submissionBeingViewed.sub_email);
									closeModal();
								}}
							>
								Ban User
							</Button>
							<Button variant="secondary" onClick={closeModal}>
								Close
							</Button>
						</Modal.Footer>
					</Fragment>
				)}
			</Modal>
		</div>
	);
};

export default AllAssetsQueue;
