import axios from "axios";

// CHANGE FOR HEROKU:

const serverUrl = "https://yoon-cmg.herokuapp.com";
// const serverUrl = "http://localhost:8080";

export const getPartners = (status) => {
	return axios
		.get(`${serverUrl}/api/partners/${status}`)
		.then((res) => res.data);
};
export const updatePartnerStatus = (user_id, status) => {
	return axios
		.put(`${serverUrl}/api/partners/update`, { user_id, status })
		.then((res) => res.data);
};
