import React, { useState, useEffect, Fragment } from "react";
import { Redirect } from "react-router-dom";
import "./Main.css";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {
	getSubmissions,
	getSubmissionsByReferrer,
	approveSubmission,
	rejectSubmission,
	undoSubmission,
	rejectUserSubs,
	publishSubmission,
	updateAdminTitle,
	updateAssetID,
	updateVideoUrl,
	approveSubmissionCID,
} from "../services/submission-requests";
import {
	getTags,
	getActiveTags,
	updateSubTags,
} from "../services/tag-requests";
import { banFromYoon, updateApprovedSubCount } from "../services/user-requests";
import ReferrerSubmissionRow from "./ReferrerSubmissionRow";
import { Video } from "cloudinary-react";

const ReferrerTable = ({ activeUser }) => {
	const [submissions, setSubmissions] = useState([]);
	const [relevantSubs, setRelevantSubs] = useState([]);
	const [cloudinaryId, setCloudinaryId] = useState("");
	const [videoFileName, setVideoFileName] = useState("");
	const [activeSubmission, setActiveSubmission] = useState("");
	const [activeTags, setActiveTags] = useState([]);
	const [allTags, setAllTags] = useState([]);
	const [modalType, setModalType] = useState("");
	const [csvState, setCSVState] = useState("");
	const [csvData, setCSVData] = useState([]);
	const [emailToggle, setEmailToggle] = useState(false);
	const [clipIDToggle, setClipIDToggle] = useState(false);
	const [userIDToggle, setUserIDToggle] = useState(false);
	const [referrerToggle, setReferrerToggle] = useState(false);
	const [titleToggle, setTitleToggle] = useState(false);
	const [adminTitleToggle, setAdminTitleToggle] = useState(false);
	useEffect(() => {
		console.log(activeUser);
		getSubmissionsByReferrer(activeUser.access_scopes).then((rows) => {
			setSubmissions(rows);
			setRelevantSubs(
				rows.sort((a, b) => {
					if (a.sub_id > b.sub_id) {
						return -1;
					}
					if (b.sub_id < a.sub_id) {
						return 1;
					}
					return 0;
				})
			);
		});
	}, []);
	const handleFilterChange = (e) => {
		const emailFilter = e.target.value;
		const filteredSubs = submissions.filter((sub) =>
			sub.sub_email.includes(emailFilter)
		);
		setRelevantSubs(filteredSubs);
	};

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const closeModal = () => setModalIsOpen(false);
	const showModal = (type, sub) => {
		setModalType(type);
		setVideoFileName(sub.file_name);
		setCloudinaryId(sub.cloudinary_id);
		setModalIsOpen(true);
	};

	const updateSubTagsFn = async (name, operation) => {
		var correctTags;
		if (operation === "add") {
			correctTags = activeTags.concat(name);
		} else {
			correctTags = activeTags.filter((tag) => tag !== name);
		}
		setActiveTags(correctTags);
		await updateSubTags(correctTags, activeSubmission);
	};

	const sortByCriteria = (rows, criteria, toggle, toggleFn, type) => {
		var compare;
		if (!type) {
			compare = (a, b) => {
				if (a[criteria] < b[criteria]) {
					if (!toggle) {
						return -1;
					} else {
						return 1;
					}
				}
				if (a[criteria] > b[criteria]) {
					if (!toggle) {
						return 1;
					} else {
						return -1;
					}
				}
				// a must be equal to b
				return 0;
			};
		}

		const sortedRows = rows.sort(compare);
		toggleFn(!toggle);
		setSubmissions(sortedRows);
		setRelevantSubs(sortedRows);
	};
	if (activeUser.status === "Unapproved") {
		return (
			<div className="main-container">
				You have not been approved for this content. Contact the admin
				for more information.
			</div>
		);
	}
	return (
		<div className="main-container">
			<div className="header-container">
				<div className="title">
					<h2>Clips from your Users</h2>
				</div>
				<div className="filters">
					Filters:
					<input
						id="email-filter"
						placeholder="Search by Email"
						onChange={handleFilterChange}
					/>
				</div>
			</div>
			<Card style={{ width: "100%" }}>
				<Card.Body>
					<Table
						style={{ width: "100%" }}
						striped
						bordered
						hover
						responsive
					>
						<thead>
							<tr>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											submissions,
											"sub_id",
											clipIDToggle,
											setClipIDToggle
										)
									}
								>
									Clip ID
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											submissions,
											"title",
											titleToggle,
											setTitleToggle
										)
									}
								>
									Title
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											submissions,
											"referrer",
											referrerToggle,
											setReferrerToggle
										)
									}
								>
									Referrer
								</th>
								<th
									className="pointer"
									style={{ minWidth: "230px" }}
									onClick={() =>
										sortByCriteria(
											submissions,
											"admin_title",
											adminTitleToggle,
											setAdminTitleToggle
										)
									}
								>
									Admin Title
								</th>
								<th>Tags</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											submissions,
											"admin_title",
											adminTitleToggle,
											setAdminTitleToggle
										)
									}
								>
									Submission Time
								</th>
								<th>View</th>
								<th>Facebook</th>
								<th>Instagram</th>
								<th>Twitter</th>
								<th>Youtube</th>
							</tr>
						</thead>
						<tbody>
							{relevantSubs.map((submission, i) => {
								return (
									<ReferrerSubmissionRow
										submission={submission}
										key={i}
										showModal={showModal}
										publishSubmission={publishSubmission}
										updateAdminTitle={updateAdminTitle}
										updateVideoUrl={updateVideoUrl}
										updateAssetID={updateAssetID}
									/>
								);
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			<Modal show={modalIsOpen} onHide={closeModal}>
				<Modal.Header closeButton>
					<Modal.Title>{videoFileName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Video
						cloudName="yoon-digital"
						publicId={cloudinaryId}
						width="460"
						controls
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ReferrerTable;
