import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";

import SiteNav from "./components/SiteNav";
import Register from "./components/Register";
import Login from "./components/Login";
import Main from "./components/Main";
import AllAssetsQueue from "./components/AllAssetsQueue";
import UserTable from "./components/UserTable";
import PartnerTable from "./components/PartnerTable";
import TagTable from "./components/TagTable";
import ReferrerTable from "./components/ReferrerTable";
import AdminDashboard from "./components/AdminDashboard";
import AdminActivityTable from "./components/AdminActivityTable";
import PasswordForm from "./components/PasswordForm";

import { authCheck } from "./services/auth-requests";

const App = (props) => {
	const [activeUser, setActiveUser] = useState({});

	useEffect(() => {
		authCheck().then((user) => {
			setActiveUser(user);
		});
	}, []);
	return (
		<Router>
			<div className="App">
				{activeUser.user_id ? (
					<SiteNav activeUser={activeUser} />
				) : null}
				<Route
					path="/"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : activeUser.status !== "Referrer" ? (
								<Main activeUser={activeUser} queue="pending" />
							) : (
								<ReferrerTable activeUser={activeUser} />
							)
						) : null
					}
				/>
				<Route
					path="/approved"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<Main
									activeUser={activeUser}
									queue="approved"
								/>
							)
						) : null
					}
				/>
				<Route
					path="/approved-cid"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<Main
									activeUser={activeUser}
									queue="approved_cid"
								/>
							)
						) : null
					}
				/>
				<Route
					path="/rejected"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<Main
									activeUser={activeUser}
									queue="rejected"
								/>
							)
						) : null
					}
				/>
				<Route
					path="/all-assets"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<AllAssetsQueue
									activeUser={activeUser}
									queue="all_assets"
								/>
							)
						) : null
					}
				/>
				<Route
					path="/users"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<UserTable activeUser={activeUser} />
							)
						) : null
					}
				/>
				<Route
					path="/partners"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<PartnerTable
									activeUser={activeUser}
									status="processing"
								/>
							)
						) : null
					}
				/>
				<Route
					path="/partners/approved"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<PartnerTable
									activeUser={activeUser}
									status="approved"
								/>
							)
						) : null
					}
				/>
				<Route
					path="/partners/rejected"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<PartnerTable
									activeUser={activeUser}
									status="rejected"
								/>
							)
						) : null
					}
				/>
				<Route
					path="/tags"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<TagTable activeUser={activeUser} />
							)
						) : null
					}
				/>
				<Route
					path="/admin-dashboard"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<AdminDashboard activeUser={activeUser} />
							)
						) : null
					}
				/>
				<Route
					path="/admin-activity-table"
					exact
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Redirect to="/login" />
							) : (
								<AdminActivityTable activeUser={activeUser} />
							)
						) : null
					}
				/>
				<Route
					path="/login"
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Login activeUser={activeUser} />
							) : (
								<Redirect to="/dashboard" />
							)
						) : null
					}
				/>
				<Route
					path="/register"
					render={() =>
						activeUser.message ? (
							!activeUser.user_id ? (
								<Register activeUser={activeUser} />
							) : (
								<Redirect to="/dashboard" />
							)
						) : null
					}
				/>
				<Route path="/reset" component={PasswordForm} />
			</div>
		</Router>
	);
};

export default App;
