import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import "./Main.css";
const SubmissionRow = ({
	submission,
	updateAdminTitle,
	updateAssetID,
	updateVideoUrl,
	showModal,
	publishSubmission,
}) => {
	const [showSubMenu, setShowSubMenu] = useState(false);
	const [isPublished, setIsPublished] = useState(submission.is_published);
	const [isCID, setIsCID] = useState(submission.status === "approved_cid");
	const [assetID, setAssetID] = useState(
		submission.asset_id ? submission.asset_id : ""
	);
	const [isEditingAssetID, setIsEditingAssetID] = useState(false);
	const [videoUrl, setVideoUrl] = useState(
		submission.video_url ? submission.video_url : ""
	);
	const [isEditingVideoUrl, setIsEditingVideoUrl] = useState(false);
	return (
		<Fragment>
			<tr style={{ cursor: "pointer" }}>
				<td onClick={() => setShowSubMenu(!showSubMenu)}>
					{submission.sub_id}
				</td>
				<td onClick={() => setShowSubMenu(!showSubMenu)}>
					{submission.title}
				</td>
				<td style={{ position: "relative" }}>{submission.referrer}</td>
				<td style={{ position: "relative" }}>
					{submission.admin_title}
				</td>
				<td style={{ minWidth: "10rem" }}>
					Current:{" "}
					{submission.tags ? submission.tags.join(", ") : "No Tags"}
				</td>
				<td
					style={{ minWidth: "8rem" }}
					onClick={() => setShowSubMenu(!showSubMenu)}
				>
					{submission.sub_time.slice(0, 10)},{" "}
					{submission.sub_time.slice(11, 19)}
				</td>
				<td>
					<div
						className="view-link"
						onClick={() => showModal("video", submission)}
					>
						View
					</div>
				</td>
				<td>{submission.facebook}</td>
				<td>{submission.instagram}</td>
				<td>{submission.twitter}</td>
				<td>{submission.youtube}</td>
			</tr>
			{showSubMenu ? (
				<tr className="sub-menu">
					<td colSpan="11">
						<span>Submitter Name: {submission.submitter_name}</span>
						<br />
						<span>
							Submitter Alt. Email: {submission.sub_alt_email}
						</span>
						<br />
						<span>Submitter Phone: {submission.phone}</span>
						<br />
						<span>Submitter Country: {submission.country}</span>
						<br />
						<span>Video Description:</span>
						<br />
						<span>{submission.description}</span>
						<br />
					</td>
				</tr>
			) : null}
		</Fragment>
	);
};

export default SubmissionRow;
