import React, { useState } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ReCAPTCHA from "react-google-recaptcha";
import { handleLogIn } from "../services/auth-requests";
import "./Auth.css";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import yoon from "../images/logo.png";

const Login = ({ goToRegister }) => {
	const recaptchaRef = React.createRef();
	const [failures, setFailures] = useState(0);
	const [captchaMessage, setCaptchaMessage] = useState("");
	const alert = useAlert();

	const onCaptchaChange = (value) => {
		if (value) {
			setCaptchaMessage("");
		}
	};

	return (
		<div className="form-container">
			<Card>
				<Card.Body>
					<div className="logo-cont">
						<img src={yoon} alt="yoon logo" />
					</div>
					<Formik
						initialValues={{ email: "", password: "" }}
						validate={(values) => {
							let errors = {};
							if (!values.email) {
								errors.email = "Required";
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
									values.email
								)
							) {
								errors.email = "Invalid email address";
							}

							if (!values.password) {
								errors.password = "Required";
							}

							if (failures >= 3) {
								const recaptchaValue = recaptchaRef.current.getValue();
								if (!recaptchaValue) {
									setCaptchaMessage("Captcha is required");
								}
							}
							return errors;
						}}
						validateOnChange="false"
						validateOnBlur="false"
						onSubmit={(values, { setSubmitting }) => {
							const { email, password } = values;
							handleLogIn(email, password).then((result) => {
								if (
									result === "Invalid Credentials" ||
									result ===
										"You must confirm your email before logging in"
								) {
									alert.error(result);
									setFailures(failures + 1);
									setSubmitting(false);
								} else {
									window.location.href = "/";
									setSubmitting(false);
								}
							});
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<Form noValidate onSubmit={handleSubmit}>
								<h2 className="auth-form-heading">Login</h2>
								<Form.Group controlId="formBasicEmail">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										className="auth-text-input"
										type="text"
										name="email"
										value={values.email}
										onChange={handleChange}
										isInvalid={!!errors.email}
										placeholder="Enter email"
									/>
									<Form.Control.Feedback type="invalid">
										{errors.email}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId="formBasicPassword">
									<Form.Label>Password</Form.Label>
									<Form.Control
										className="auth-text-input"
										type="password"
										name="password"
										value={values.password}
										onChange={handleChange}
										isInvalid={!!errors.password}
										placeholder="Password"
									/>
									<Form.Control.Feedback type="invalid">
										{errors.password}
									</Form.Control.Feedback>
								</Form.Group>
								{failures >= 3 ? (
									<div>
										<ReCAPTCHA
											ref={recaptchaRef}
											sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
											onChange={onCaptchaChange}
										/>
										<div className="input-feedback captcha-feedback">
											{captchaMessage}
										</div>
									</div>
								) : null}
								<div className="form-submit-container">
									<Link
										className="auth-btn-secondary"
										to="/register"
									>
										Sign Up
									</Link>
									<Button variant="primary" type="submit">
										Submit
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Card.Body>
			</Card>
		</div>
	);
};

export default Login;
