import React, { useState, useEffect } from "react";
import "./Main.css";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { getUsers } from "../services/user-requests";
import { rejectUserSubs } from "../services/submission-requests";
import { banFromYoon, unbanFromYoon } from "../services/user-requests";
import "./Main.css";

const UserTable = ({ activeUser }) => {
	const [users, setUsers] = useState([]);
	const [relevantUsers, setRelevantUsers] = useState([]);
	const [csvData, setCSVData] = useState([]);
	const [csvState, setCSVState] = useState("");
	const [emailToggle, setEmailToggle] = useState(false);
	const [userIDToggle, setUserIDToggle] = useState(false);
	const [userSubsToggle, setUserSubsToggle] = useState(false);
	const [approvedSubsToggle, setApprovedSubsToggle] = useState(false);
	const [referrerToggle, setReferrerToggle] = useState(false);
	const [ipAddressToggle, setIPAddressToggle] = useState(false);
	const [locationToggle, setLocationToggle] = useState(false);
	const [signUpDateToggle, setSignUpDateToggle] = useState(false);

	// CHANGE FOR HEROKU:
	console.log(users);
	const yoonUrl = "https://yoon-cmg.herokuapp.com";
	// const yoonUrl = "http://localhost:8080";
	useEffect(() => {
		getUsers().then((rows) => {
			setUsers(rows);
			setRelevantUsers(rows);
		});
	}, []);
	const banUser = async (email) => {
		window.confirm("Are you sure?");
		await rejectUserSubs(email);
		await banFromYoon(email);
		window.alert("User has been banned");
	};
	const unbanUser = async (email) => {
		window.confirm("Are you sure?");
		await unbanFromYoon(email);
		window.alert("User has been unbanned");
	};
	const handleFilterChange = (e) => {
		const emailFilter = e.target.value;
		const filteredUsers = users.filter((user) =>
			user.email.includes(emailFilter)
		);
		console.log(filteredUsers);
		setRelevantUsers(filteredUsers);
	};

	const exportToCSV = (rows) => {
		setCSVState("loading");
		const headerRow = [
			"ID",
			"Email",
			"User Submissions",
			"Approved Submissions",
			"Referrer",
			"IP Address",
			"Location",
			"Status",
			"Sign Up Date",
			"Sign Up Method",
			"Facebook",
			"Instagram",
			"Twitter",
			"Youtube",
			"Youtube Partner Channel",
			"Partner Channel ID#",
		];
		const rowsCSV = rows.map((row) => {
			return [
				row.user_id,
				row.email,
				row.uploads,
				row.approved_subs,
				row.referrer,
				`"${row.ip_address}"`,
				`"${row.city}, ${row.region ? `${row.region}, ` : ""}${
					row.country
				}"`,
				row.status,
				row.sign_up_date.slice(0, 10),
				row.signup_method,
				row.facebook,
				row.instagram,
				row.twitter,
				row.youtube,
				row.channel_title,
				row.channel_id,
			];
		});
		rowsCSV.unshift(headerRow);
		const csvString = rowsCSV.map((row) => row.join(",")).join("\n");
		console.log(csvString);
		setCSVState("ready");
		var download = document.getElementById("csv-download");
		download.setAttribute(
			"href",
			"data:text/csv;charset=utf-8," + encodeURIComponent(csvString)
		);
		download.setAttribute("download", "user_table.csv");
	};
	const sortByCriteria = (rows, criteria, toggle, toggleFn, type) => {
		var compare;
		if (!type) {
			compare = (a, b) => {
				console.log(criteria);
				console.log(b[criteria]);
				console.log(a[criteria]);
				if (a[criteria] < b[criteria]) {
					if (!toggle) {
						return -1;
					} else {
						return 1;
					}
				}
				if (a[criteria] > b[criteria]) {
					if (!toggle) {
						return 1;
					} else {
						return -1;
					}
				}
				// a must be equal to b
				return 0;
			};
		}

		const sortedRows = rows.sort(compare);
		console.log(sortedRows);
		console.log(toggle);
		toggleFn(!toggle);
		setUsers(sortedRows);
		setRelevantUsers(sortedRows);
	};
	if (activeUser.status === "Unapproved") {
		return (
			<div className="main-container">
				You have not been approved for this content. Contact the admin
				for more information.
			</div>
		);
	}
	return (
		<div className="main-container">
			<div className="header-container">
				<div className="title">
					<h2>User Table</h2>
					<div className="export-to-csv">
						<a
							href="javascript:void(0)"
							className="csv-link"
							onClick={() => exportToCSV(users)}
						>
							Export to CSV
						</a>
						{csvState === "loading" ? (
							<span>Loading...</span>
						) : null}
						<a
							id="csv-download"
							className={csvState === "ready" ? "" : "hidden"}
						>
							Download
						</a>
					</div>
				</div>
				<div className="filters">
					Filters:
					<input
						id="email-filter"
						placeholder="Search by Email"
						onChange={handleFilterChange}
					/>
				</div>
			</div>
			<Card style={{ width: "100%" }}>
				<Card.Body>
					<Table
						style={{ width: "100%" }}
						striped
						bordered
						hover
						responsive
						className="fixed-header-table"
					>
						<thead>
							<tr>
								<th
									className="pointer x-half"
									onClick={() =>
										sortByCriteria(
											users,
											"user_id",
											userIDToggle,
											setUserIDToggle
										)
									}
								>
									ID#
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											users,
											"email",
											emailToggle,
											setEmailToggle
										)
									}
								>
									Email
								</th>
								<th
									className="pointer x-half"
									onClick={() =>
										sortByCriteria(
											users,
											"uploads",
											userSubsToggle,
											setUserSubsToggle
										)
									}
								>
									User Submissions
								</th>
								<th
									className="pointer x-half"
									onClick={() =>
										sortByCriteria(
											users,
											"uploads",
											approvedSubsToggle,
											setApprovedSubsToggle
										)
									}
								>
									Approved Submissions
								</th>
								<th
									className="pointer x-half"
									onClick={() =>
										sortByCriteria(
											users,
											"uploads",
											referrerToggle,
											setReferrerToggle
										)
									}
								>
									Referrer
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											users,
											"ip_address",
											ipAddressToggle,
											setIPAddressToggle
										)
									}
								>
									IP Address
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											users,
											"city",
											locationToggle,
											setLocationToggle
										)
									}
								>
									Location
								</th>
								<th
									className="pointer x-half"
									onClick={() =>
										sortByCriteria(
											users,
											"sign_up_date",
											signUpDateToggle,
											setSignUpDateToggle
										)
									}
								>
									Sign Up Date
								</th>
								<th>Youtube Partner Channel</th>
								<th className="x-half">Sign Up Method</th>
								<th className="x-half">Action</th>
								<th className="x-half">Login As User</th>
							</tr>
						</thead>
						<tbody>
							{relevantUsers.map((user, i) => {
								const userInfo = user.email.split("@");
								return (
									<tr key={i}>
										<td className="x-half">
											{user.user_id}
										</td>
										<td>{user.email}</td>
										<td className="x-half">
											{user.uploads}
										</td>
										<td className="x-half">
											{user.approved_subs}
										</td>
										<td className="x-half">
											{user.referrer}
										</td>
										<td>{user.ip_address}</td>
										<td>{`${user.city}, ${
											user.region
												? `${user.region}, `
												: ""
										}${user.country}`}</td>
										<td className="x-half">
											{user.sign_up_date.slice(0, 10)}
										</td>
										<td>
											<a
												href={`https://youtube.com/channel/${user.channel_id}`}
											>
												{user.channel_title}
											</a>
										</td>
										<td className="x-half">
											{user.signup_method}
										</td>
										<td className="x-half">
											{user.status === "banned" ? (
												<Button
													onClick={() =>
														unbanUser(user.email)
													}
													className="submission-btn-container"
												>
													Unban User
												</Button>
											) : (
												<Button
													onClick={() =>
														banUser(user.email)
													}
													className="submission-btn-container"
												>
													Ban User
												</Button>
											)}
										</td>
										<td className="x-half">
											<a
												href={`${yoonUrl}/admin-login?user=${userInfo[0]}&domain=${userInfo[1]}&id=${user.hash}&yoon_id=${user.user_id}`}
												target="_blank"
											>
												<Button className="submission-btn-container">
													Login
												</Button>
											</a>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</div>
	);
};

export default UserTable;
