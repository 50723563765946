import axios from "axios";

const serverUrl = "https://yoon-cmg.herokuapp.com";

export const getSubmissions = () => {
	return axios.get(`/api/submissions/`).then((res) => res.data);
};

export const getSubmissionsByQueue = (queue) => {
	return axios.get(`/api/submissions/queue/${queue}`).then((res) => res.data);
};

export const getSubmissionsByReferrer = (access_scopes) => {
	return axios
		.post(`/api/submissions/referrer`, { access_scopes })
		.then((res) => res.data);
};

export const approveSubmission = (id, user_id) => {
	return axios
		.post(`/api/submissions/approve`, { id, user_id })
		.then((res) => res.data);
};

export const approveSubmissionCID = (id, user_id) => {
	return axios
		.post(`/api/submissions/approve-cid`, { id, user_id })
		.then((res) => res.data);
};

export const rejectSubmission = (id, email, user_id, rejection_text) => {
	axios
		.post(`${serverUrl}/api/users/reject-sub`, { email })
		.then((res) => res.data);
	return axios
		.post(`/api/submissions/reject`, { id, user_id, rejection_text })
		.then((res) => res.data);
};

export const undoSubmission = (id) => {
	return axios.post(`/api/submissions/undo`, { id }).then((res) => res.data);
};

export const rejectUserSubs = (email, user_id) => {
	return axios
		.post(`/api/submissions/ban/`, { email, user_id })
		.then((res) => res.data);
};

export const publishSubmission = (id, bool) => {
	return axios
		.post(`/api/submissions/publish/`, { id, bool })
		.then((res) => res.data);
};
export const updateAdminTitle = (id, title) => {
	return axios
		.post(`/api/submissions/admin-title/`, { id, title })
		.then((res) => res.data);
};

export const updateAssetID = (id, assetID) => {
	return axios
		.post(`/api/submissions/asset-id/`, { id, assetID })
		.then((res) => res.data);
};

export const updateAssetID2 = (id, assetID) => {
	return axios
		.post(`/api/submissions/asset-id-2/`, { id, assetID })
		.then((res) => res.data);
};

export const updateVideoUrl = (id, videoUrl) => {
	return axios
		.post(`/api/submissions/video-url/`, { id, videoUrl })
		.then((res) => res.data);
};

export const assignAdmin = (ids, admin) => {
	return axios
		.post(`/api/submissions/assign-admin/`, { ids, admin })
		.then((res) => res.data);
};
