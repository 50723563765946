import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { submitReset } from "../services/auth-requests";
import "./Auth.css";
import { useAlert } from "react-alert";

const PasswordForm = () => {
	const alert = useAlert();
	const [userToken, setUserToken] = useState("");
	const [hashToken, setHashToken] = useState("");
	const [dummy, setDummy] = useState("");
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		setUserToken(params.get("u"));
		setHashToken(params.get("t"));
		setDummy(params.get("h"));
	}, []);
	return (
		<div className="auth-container single-form">
			<div className="pw-form">
				<Card>
					<Card.Body>
						<Formik
							initialValues={{
								password: "",
								confirm: "",
							}}
							validate={(values) => {
								let errors = {};
								const { password, confirm } = values;
								const hasNumbers = /\d/.test(password);
								const hasNonalphas = /\W/.test(password);

								if (
									password.length < 6 ||
									!(hasNumbers || hasNonalphas)
								) {
									errors.password =
										"Password must be at least 6 characters and contain either a number or a special character.";
								}
								if (!password) {
									errors.password = "Required";
								}

								if (confirm !== password) {
									errors.confirm = "Passwords must match";
								}
								return errors;
							}}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={async (values, { setSubmitting }) => {
								const { password } = values;
								submitReset(
									password,
									userToken,
									hashToken,
									dummy
								).then((res) => {
									if (res.includes("Error")) {
										setSubmitting(false);
										alert.error(res);
									} else {
										alert.success(res);
										setSubmitting(false);
										setTimeout(() => {
											window.location.href = "/login";
										}, 1500);
									}
								});
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
							}) => (
								<Form noValidate onSubmit={handleSubmit}>
									<h2
										className="auth-form-heading"
										style={{ marginBottom: "1.5rem" }}
									>
										Finish Account Setup
									</h2>
									<Form.Group
										className="form-group-custom"
										controlId="formBasicPassword"
									>
										<div className="label-container">
											<Form.Label>
												New Password
											</Form.Label>
											<div className="errors">
												{errors.password}
											</div>
										</div>
										<Form.Control
											className="auth-text-input"
											type="password"
											name="password"
											value={values.password}
											onChange={handleChange}
											isInvalid={!!errors.password}
											placeholder="Password"
										/>
									</Form.Group>
									<Form.Group
										className="form-group-custom"
										controlId="formBasicPassword"
									>
										<div className="label-container">
											<Form.Label>Confirm </Form.Label>
											<div className="errors">
												{errors.confirm}
											</div>
										</div>
										<Form.Control
											className="auth-text-input"
											type="password"
											name="confirm"
											value={values.confirm}
											onChange={handleChange}
											isInvalid={!!errors.confirm}
											placeholder="Confirm"
										/>
									</Form.Group>
									<div className="form-container-centered form-submit-container">
										<Button
											variant="primary"
											className="relative-btn"
											type="submit"
										>
											<span className="text">Submit</span>
										</Button>
									</div>
								</Form>
							)}
						</Formik>
					</Card.Body>
				</Card>
			</div>
		</div>
	);
};

export default PasswordForm;
