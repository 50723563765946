import axios from "axios";

// CHANGE FOR HEROKU:

const serverUrl = "https://yoon-cmg.herokuapp.com";
// const serverUrl = "http://localhost:8080";

export const getDashboardUsers = () => {
	return axios.get(`/api/users/`).then((res) => res.data);
};

export const updateDashboardUserStatus = (user_id, status, access_scopes) => {
	return axios
		.post(`/api/users/update-status`, {
			user_id,
			status,
			access_scopes,
		})
		.then((res) => res.data);
};

export const getUsers = () => {
	return axios.get(`${serverUrl}/api/users/`).then((res) => res.data);
};

export const banFromYoon = (email) => {
	return axios
		.post(`${serverUrl}/api/users/ban`, {
			email,
		})
		.then((res) => res.data);
};

export const unbanFromYoon = (email) => {
	return axios
		.post(`${serverUrl}/api/users/unban`, {
			email,
		})
		.then((res) => res.data);
};

export const contactUser = (email) => {
	return axios
		.post(`${serverUrl}/api/users/contactUser`, {
			email,
		})
		.then((res) => res.data);
};

export const updateApprovedSubCount = (user_id, type) => {
	return axios
		.post(`${serverUrl}/api/users/update-sub-count`, {
			user_id,
			type,
		})
		.then((res) => res.data);
};

export const getAdmins = () => {
	return axios.get(`/api/users/admin`).then((res) => res.data);
};

export const getAllAdmins = () => {
	return axios.get(`/api/users/all-admins`).then((res) => res.data);
};

export const getAdminDataForDateRange = (startDate, endDate) => {
	return axios.post(`/api/users/admin-data`, {
		startDate,
		endDate,
	});
};
