import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./ReferrerWidget.css";

const ReferrerWidget = ({
	referrers,
	setReferrers,
	addReferrer,
	deleteReferrer,
}) => {
	const [referrerToAdd, setReferrerToAdd] = useState("");
	return (
		<div className="referrers">
			<Card style={{ width: "100%" }}>
				<Card.Body>
					<div className="card-inner">
						<h6>Referrers:</h6>
						<div>
							<div className="referrers-container">
								{referrers.map((r, i) => (
									<div
										className="btn btn-primary referrer"
										key={i}
									>
										<span className="name">{r.name}</span>
										<span
											onClick={() => deleteReferrer(r.id)}
											className="close"
										>
											&times;
										</span>
									</div>
								))}
							</div>
							<div className="referrer-form">
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										addReferrer(referrerToAdd);
									}}
								>
									<div className="referrer-form-group">
										<Form.Group controlId="referrer">
											<Form.Control
												type="text"
												placeholder="referrer"
												value={referrerToAdd}
												onChange={(e) =>
													setReferrerToAdd(
														e.target.value
													)
												}
											/>
										</Form.Group>
										<Button variant="primary" type="submit">
											Add
										</Button>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
};

export default ReferrerWidget;
