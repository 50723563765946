import React, { useState, useEffect, Fragment } from "react";
import "./Main.css";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import "./Main.css";
import {
	getDashboardUsers,
	updateDashboardUserStatus,
} from "../services/user-requests";
import ReferrerWidget from "./ReferrerWidget";
import AddUserModal from "./AddUserModal";
import axios from "axios";
import { SERVER_URL } from "../constants/server";

const AdminDashboard = ({ activeUser, queue }) => {
	const [users, setUsers] = useState([]);
	const [unApprovedUsers, setUnApprovedUsers] = useState([]);
	const [unapprovedFilterText, setUnapprovedFilterText] = useState("");
	const [approvedUsers, setApprovedUsers] = useState([]);
	const [approvedFilterText, setApprovedFilterText] = useState("");
	const [userBeingEdited, setUserBeingEdited] = useState({});
	const [userBeingEditedIndex, setUserBeingEditedIndex] = useState(0);
	const [userBeingEditedCurrType, setUserBeingEditedCurrType] = useState("");
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [referrers, setReferrers] = useState([]);
	const [allAccessScopes, setAllAccessScopes] = useState([]);
	const [refetch, setRefetch] = useState(false);
	const [userModalIsOpen, setUserModalIsOpen] = useState(false);
	useEffect(() => {
		axios.get(`${SERVER_URL}/api/referrers/`).then((res) => {
			setReferrers(res.data.referrers);
		});
	}, [refetch]);
	useEffect(() => {
		setAllAccessScopes(referrers.map((r) => r.name));
	}, [referrers]);
	const addReferrer = (name) => {
		axios
			.post(`${SERVER_URL}/api/referrers/create`, { name })
			.then((res) => setRefetch((r) => !r));
	};
	const deleteReferrer = (id) => {
		axios
			.delete(`${SERVER_URL}/api/referrers/${id}`)
			.then((res) => setRefetch((r) => !r));
	};

  const handleApprovedFilterChange = (e) => {
		const emailFilter = e.target.value;
    setApprovedFilterText(emailFilter);

		const filteredUsers = users.filter((user) =>
			user.email.includes(emailFilter) && user.status !== "Unapproved"
		);
		console.log(filteredUsers);
		setApprovedUsers(filteredUsers);
	};
  const handleUnapprovedFilterChange = (e) => {
		const emailFilter = e.target.value;
    setUnapprovedFilterText(emailFilter);

		const filteredUsers = users.filter((user) =>
			user.email.includes(emailFilter) && user.status === "Unapproved"
		);
		console.log(filteredUsers);
		setUnApprovedUsers(filteredUsers);
	};

	const [allStatuses, setAllStatuses] = useState([
		"Unapproved",
		"Referrer",
		"Admin",
		"Super Admin",
	]);
	const closeModal = () => setModalIsOpen(false);
	const showModal = (user, type) => {
		setModalIsOpen(true);
		setUserBeingEdited(user);
		setUserBeingEditedCurrType(type);
	};

	const closeUserModal = () => setUserModalIsOpen(false);
	const showUserModal = () => setUserModalIsOpen(true);
	const saveUserFn = async (user) => {
		if (user) {
			console.log(user);
			await updateDashboardUserStatus(
				user.user_id,
				user.status,
				user.access_scopes
			);
			fetchUsers();
		}
	};
	const updateUsers = (type, idx, attr, val) => {
		if (type === "Unapproved") {
			setUnApprovedUsers((prev) => {
				console.log("PREV: ", prev);
				const copy = prev.slice(0, prev.length);
				copy[idx][attr] = val;
				return copy;
			});
		} else {
			setApprovedUsers((prev) => {
				console.log("PREV: ", prev);
				const copy = prev.slice(0, prev.length);
				copy[idx][attr] = val;
				return copy;
			});
		}
	};
	const fetchUsers = () => {
		getDashboardUsers().then((rows) => {
			setUsers(rows);
			const unappr = rows.filter((u) => u.status === "Unapproved" && u.email.includes(unapprovedFilterText));
			const appr = rows.filter((u) => u.status !== "Unapproved" && u.email.includes(approvedFilterText));
			setUnApprovedUsers(unappr);
			setApprovedUsers(appr);
		});
	};
	useEffect(fetchUsers, []);
	if (activeUser.status === "Unapproved") {
		return (
			<div className="main-container">
				You have not been approved for this content. Contact the admin
				for more information.
			</div>
		);
	}
	return (
		<Fragment>
			<div className="main-container">
				<ReferrerWidget
					referrers={referrers}
					setReferrers={setReferrers}
					addReferrer={addReferrer}
					deleteReferrer={deleteReferrer}
				/>
				<div className="header-container admin-dboard-header-container">
					<div className="title">
						<h2>Unapproved Users</h2>
						<Button variant="success" onClick={showUserModal}>
							Add a user
						</Button>
					</div>
          <div className="filters">
            Filters:
            <input
              id="unapproved-email-filter"
              placeholder="Search by Email"
              onChange={handleUnapprovedFilterChange}
            />
          </div>
				</div>
				<Card style={{ width: "100%" }}>
					<Card.Body>
						<Table
							style={{ width: "100%" }}
							striped
							bordered
							hover
							responsive
						>
							<thead>
								<tr>
									<th>User Email</th>
									<th>User Status</th>
									<th>Access Scopes (if referrer)</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{unApprovedUsers.map((user, i) => {
									return (
										<tr key={i}>
											<td>{user.email}</td>
											<td>
												<Dropdown>
													<Dropdown.Toggle
														variant="secondary"
														id="dropdown-basic"
													>
														{user.status}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														{allStatuses.map(
															(status, j) => {
																return (
																	<Dropdown.Item
																		key={j}
																		eventKey={
																			j
																		}
																		onClick={() => {
																			updateUsers(
																				"Unapproved",
																				i,
																				"status",
																				status
																			);
																		}}
																	>
																		{status}
																	</Dropdown.Item>
																);
															}
														)}
													</Dropdown.Menu>
												</Dropdown>
											</td>
											<td>
												{user.status === "Referrer" ? (
													<div
														onClick={() => {
															showModal(
																user,
																"Unapproved"
															);
															setUserBeingEditedIndex(
																i
															);
														}}
													>
														Current:{" "}
														{user.access_scopes
															? user.access_scopes.join(
																	", "
															  )
															: "No Access Scopes"}
														<br />
														<span className="view-link">
															Edit Access Scopes
														</span>
													</div>
												) : null}
											</td>
											<td>
												<Button
													onClick={() => {
														saveUserFn({
															user_id:
																user.user_id,
															status: user.status,
															access_scopes:
																user.access_scopes,
														});
													}}
													variant="primary"
												>
													Save
												</Button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
				<div className="header-container">
					<div className="title">
						<h2>Approved Users</h2>
					</div>
          <div className="filters">
            Filters:
            <input
              id="approved-email-filter"
              placeholder="Search by Email"
              onChange={handleApprovedFilterChange}
            />
          </div>
				</div>
				<Card style={{ width: "100%" }}>
					<Card.Body>
						<Table
							style={{ width: "100%" }}
							striped
							bordered
							hover
							responsive
						>
							<thead>
								<tr>
									<th>User Email</th>
									<th>User Status</th>
									<th>Access Scopes (if referrer)</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{approvedUsers.map((user, i) => {
									return (
										<tr key={i}>
											<td>{user.email}</td>
											<td>
												<Dropdown>
													<Dropdown.Toggle
														variant="secondary"
														id="dropdown-basic"
													>
														{user.status}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														{allStatuses.map(
															(status, j) => {
																return (
																	<Dropdown.Item
																		key={j}
																		eventKey={
																			j
																		}
																		onClick={() => {
																			updateUsers(
																				"Approved",
																				i,
																				"status",
																				status
																			);
																		}}
																	>
																		{status}
																	</Dropdown.Item>
																);
															}
														)}
													</Dropdown.Menu>
												</Dropdown>
											</td>
											<td>
												{user.status === "Referrer" ? (
													<div
														onClick={() => {
															showModal(
																user,
																"Approved"
															);
															setUserBeingEditedIndex(
																i
															);
														}}
													>
														Current:{" "}
														{user.access_scopes
															? (user.access_scopes.join && user.access_scopes.join(
																	", "
															  ))
															: "No Access Scopes"}
														<br />
														<span className="view-link">
															Edit Access Scopes
														</span>
													</div>
												) : null}
											</td>
											<td>
												<Button
													onClick={() => {
														saveUserFn({
															user_id:
																user.user_id,
															status: user.status,
															access_scopes:
																user.access_scopes,
														});
													}}
													variant="primary"
												>
													Save
												</Button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
				<Modal show={modalIsOpen} onHide={closeModal}>
					<Modal.Header closeButton>
						<Modal.Title>Access Scopes</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h6>Current Access Scopes</h6>
						<div className="tag-container">
							{(userBeingEdited.access_scopes && userBeingEdited.access_scopes.map)
								? userBeingEdited.access_scopes.map(
										(scope, i) => {
											if (!scope) {
												return null;
											}
											return (
												<div
													className="btn btn-primary tag"
													key={i}
												>
													<span className="name">
														{scope}
													</span>
													<span
														onClick={() => {
															const scopes = allAccessScopes.filter(
																(s) => {
																	if (
																		s ===
																		scope
																	)
																		return false;
																	if (
																		!userBeingEdited.access_scopes
																	)
																		return false;
																	return userBeingEdited.access_scopes.includes(
																		s
																	);
																}
															);
															console.log(
																userBeingEditedCurrType,
																userBeingEditedIndex,
																"access_scopes",
																scopes
															);
															updateUsers(
																userBeingEditedCurrType,
																userBeingEditedIndex,
																"access_scopes",
																scopes
															);
														}}
														className="close"
													>
														&times;
													</span>
												</div>
											);
										}
								  )
								: null}
						</div>
						<div className="add-tags">
							<Dropdown>
								<Dropdown.Toggle
									variant="secondary"
									id="dropdown-basic"
								>
									Add Access Scopes
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{allAccessScopes
										.filter((scope) => {
											if (!userBeingEdited.access_scopes)
												return true;
											return !userBeingEdited.access_scopes.includes(
												scope
											);
										})
										.map((scope, i) => {
											return (
												<Dropdown.Item
													key={i}
													eventKey={i}
													onClick={() => {
														const scopes = allAccessScopes.filter(
															(s) => {
																if (s === scope)
																	return true;
																if (
																	!userBeingEdited.access_scopes
																)
																	return false;
																return userBeingEdited.access_scopes.includes(
																	s
																);
															}
														);
														console.log(
															userBeingEditedCurrType,
															userBeingEditedIndex,
															"access_scopes",
															scopes
														);
														updateUsers(
															userBeingEditedCurrType,
															userBeingEditedIndex,
															"access_scopes",
															scopes
														);
													}}
												>
													{scope}
												</Dropdown.Item>
											);
										})}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={closeModal}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
				<AddUserModal
					modalIsOpen={userModalIsOpen}
					closeModal={closeUserModal}
					fetchUsers={fetchUsers}
				/>
			</div>
		</Fragment>
	);
};

export default AdminDashboard;
