import React from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./Auth.css";
import { handleRegister } from "../services/auth-requests";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import yoon from "../images/logo.png";

const Register = ({ goToLogin }) => {
	const alert = useAlert();
	return (
		<div className="form-container">
			<Card>
				<Card.Body>
					<div className="logo-cont">
						<img src={yoon} alt="yoon logo" />
					</div>
					<Formik
						initialValues={{ email: "", password: "", confirm: "" }}
						validate={(values) => {
							const { email, password, confirm } = values;
							let errors = {};
							if (!email) {
								errors.email = "Required";
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
									email
								)
							) {
								errors.email = "Invalid email address";
							}
							const hasUpperCase = /[A-Z]/.test(password);
							const hasLowerCase = /[a-z]/.test(password);
							const hasNumbers = /\d/.test(password);
							const hasNonalphas = /\W/.test(password);

							if (
								password.length < 8 ||
								hasUpperCase +
									hasLowerCase +
									hasNumbers +
									hasNonalphas <
									3
							) {
								errors.password =
									"Password must be at least 8 characters and use at least 3 of the following character types: (a) uppercase letters, (b) lowercase letters, (c) numbers, and/or (d) special characters.";
							}
							if (!password) {
								errors.password = "Required";
							}
							if (confirm !== password) {
								errors.confirm = "Passwords must match";
							}
							return errors;
						}}
						validateOnChange="false"
						validateOnBlur="false"
						onSubmit={(values, { setSubmitting }) => {
							const { email, password } = values;
							handleRegister(email, password).then((result) => {
								if (result.success === true) {
									window.location.href = "/";
									setSubmitting(false);
								} else {
									alert.error(result);
									setSubmitting(false);
								}
							});
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<Form noValidate onSubmit={handleSubmit}>
								<h2 className="auth-form-heading">Register</h2>
								<Form.Group controlId="formBasicEmail">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										className="auth-text-input"
										type="text"
										name="email"
										value={values.email}
										onChange={handleChange}
										isInvalid={!!errors.email}
										placeholder="Enter email"
									/>
									<Form.Control.Feedback type="invalid">
										{errors.email}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId="formBasicPassword">
									<Form.Label>Password</Form.Label>
									<Form.Control
										className="auth-text-input"
										type="password"
										name="password"
										value={values.password}
										onChange={handleChange}
										isInvalid={!!errors.password}
										placeholder="Password"
									/>
									<Form.Control.Feedback type="invalid">
										{errors.password}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId="formBasicConfirm">
									<Form.Label>Confirm Password</Form.Label>
									<Form.Control
										className="auth-text-input"
										type="password"
										name="confirm"
										value={values.confirm}
										onChange={handleChange}
										isInvalid={!!errors.confirm}
										placeholder="Confirm"
									/>
									<Form.Control.Feedback type="invalid">
										{errors.confirm}
									</Form.Control.Feedback>
								</Form.Group>
								<div className="form-submit-container">
									<Link
										className="auth-btn-secondary"
										to="/login"
									>
										Sign In
									</Link>
									<Button variant="primary" type="submit">
										Submit
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Card.Body>
			</Card>
		</div>
	);
};

export default Register;
