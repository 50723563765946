import axios from "axios";

export const authCheck = () => {
	return axios.get("/api/auth").then((res) => res.data);
};

export const handleLogIn = (email, password) => {
	return axios
		.post("/api/auth/login", { email, password })
		.then((res) => res.data);
};

export const handleRegister = (email, password) => {
	return axios
		.post("/api/auth/register", { email, password })
		.then((res) => res.data);
};

export const handleLogOut = (email, password) => {
	return axios.get("/api/auth/logout").then((res) => res.data);
};

export const sendUserCreateEmail = (email) => {
	return axios
		.post("/api/auth/send-user-create", { email })
		.then((res) => res.data);
};

export const submitReset = (password, userID, userToken, dummy) => {
	return axios
		.post("/api/auth/submit-reset", {
			password,
			userID,
			userToken,
			hashToken: dummy,
		})
		.then((res) => res.data);
};
