import React, { useState, useEffect, Fragment } from "react";
import "./Main.css";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { getPartners, updatePartnerStatus } from "../services/partner-requests";
import { rejectUserSubs } from "../services/submission-requests";
import { banFromYoon, unbanFromYoon } from "../services/user-requests";
import "./Main.css";

const PartnerTable = ({ activeUser, status }) => {
	const [partners, setPartners] = useState([]);
	const [relevantPartners, setRelevantPartners] = useState([]);
	const [emailToggle, setEmailToggle] = useState(false);
	const [userIDToggle, setUserIDToggle] = useState(false);
	const fetchPartners = () => {
		getPartners(status).then((rows) => {
			setPartners(rows);
			setRelevantPartners(rows);
		});
	};
	useEffect(fetchPartners, []);
	const handleFilterChange = (e) => {
		const emailFilter = e.target.value;
		const filteredUsers = partners.filter((partner) =>
			partner.email.includes(emailFilter)
		);
		setRelevantPartners(filteredUsers);
	};

	const sortByCriteria = (rows, criteria, toggle, toggleFn, type) => {
		var compare;
		if (!type) {
			compare = (a, b) => {
				if (a[criteria] < b[criteria]) {
					if (!toggle) {
						return -1;
					} else {
						return 1;
					}
				}
				if (a[criteria] > b[criteria]) {
					if (!toggle) {
						return 1;
					} else {
						return -1;
					}
				}
				// a must be equal to b
				return 0;
			};
		}

		const sortedRows = rows.sort(compare);
		toggleFn(!toggle);
		setPartners(sortedRows);
		setRelevantPartners(sortedRows);
	};
	if (activeUser.status === "Unapproved") {
		return (
			<div className="main-container">
				You have not been approved for this content. Contact the admin
				for more information.
			</div>
		);
	}
	return (
		<div className="main-container">
			<div className="header-container">
				<div className="title">
					<h2>Partner Table</h2>
				</div>
				<div className="filters">
					Filters:
					<input
						id="email-filter"
						placeholder="Search by Email"
						onChange={handleFilterChange}
					/>
				</div>
			</div>
			<Card style={{ width: "100%" }}>
				<Card.Body>
					<Table
						style={{ width: "100%" }}
						striped
						bordered
						hover
						responsive
					>
						<thead>
							<tr>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											partners,
											"user_id",
											userIDToggle,
											setUserIDToggle
										)
									}
								>
									ID#
								</th>
								<th
									className="pointer"
									onClick={() =>
										sortByCriteria(
											partners,
											"email",
											emailToggle,
											setEmailToggle
										)
									}
								>
									Email
								</th>
								<th className="">Channel</th>
								<th className="">Created At</th>
								<th className="">Applied At</th>
								<th className="">Community Status</th>
								<th className="">View Count</th>
								<th className="">Video Count</th>
								<th className="">Subscriber Count</th>
								<th className="">Actions</th>
							</tr>
						</thead>
						<tbody>
							{relevantPartners.map((partner, i) => {
								return (
									<tr key={i}>
										<td>{partner.user_id}</td>
										<td>{partner.email}</td>
										<td>
											<a
												href={`https://youtube.com/channel/${partner.channel_id}`}
											>
												{partner.channel_title}
											</a>
										</td>
										<td>
											{partner.published_at
												? partner.published_at.slice(
														0,
														10
												  )
												: ""}
										</td>
										<td>
											{partner.applied_at
												? partner.applied_at.slice(
														0,
														10
												  )
												: ""}
										</td>
										<td>
											{partner.overall_good_standing
												? "Good Standing"
												: ""}
											{!partner.community_guidelines_good_standing ? (
												<Fragment>
													Not in good standing with
													respect to community
													guidelines
													<br />
												</Fragment>
											) : (
												""
											)}
											{!partner.copyright_strikes_good_standing ? (
												<Fragment>
													Not in good standing with
													respect to copyright strikes
													<br />
												</Fragment>
											) : (
												""
											)}
											{!partner.content_id_claims_good_standing ? (
												<Fragment>
													Not in good standing with
													respect to content id claims
													<br />
												</Fragment>
											) : (
												""
											)}
										</td>
										<td>{partner.view_count}</td>
										<td>{partner.video_count}</td>
										<td>{partner.subscriber_count}</td>
										{status === "processing" ? (
											<td className="submission-btn-container">
												<Fragment>
													<Button
														variant="success"
														onClick={async () => {
															await updatePartnerStatus(
																partner.user_id,
																"approved"
															);
															fetchPartners();
														}}
													>
														Approve
													</Button>
													<Button
														variant="danger"
														onClick={async () => {
															await updatePartnerStatus(
																partner.user_id,
																"rejected"
															);
															fetchPartners();
														}}
													>
														Reject
													</Button>
												</Fragment>
											</td>
										) : null}
										{status === "approved" ? (
											<td>
												<Button
													onClick={async () => {
														await updatePartnerStatus(
															partner.user_id,
															"processing"
														);
														fetchPartners();
													}}
												>
													Undo
												</Button>
											</td>
										) : null}
										{status === "rejected" ? (
											<td>
												<Button
													onClick={async () => {
														await updatePartnerStatus(
															partner.user_id,
															"processing"
														);
														fetchPartners();
													}}
												>
													Undo
												</Button>
											</td>
										) : null}
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</div>
	);
};

export default PartnerTable;
