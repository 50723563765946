import React, { useState, useEffect, Fragment } from "react";
import "./Main.css";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Main.css";
import {
	getTags,
	createTag,
	deleteTag,
	deleteTagFromSubs,
} from "../services/tag-requests";

const TagTable = ({ activeUser, queue }) => {
	const [tags, setTags] = useState([]);
	const [tagToCreate, setTagToCreate] = useState("");
	const [tagToDelete, setTagToDelete] = useState("");
	const [tagsUpdated, setTagsUpdated] = useState(0);
	const [show, setShow] = useState(false);

	const createTagFn = async () => {
		if (tagToCreate) {
			await createTag(tagToCreate);
			setTagToCreate("");
			fetchTags();
		}
	};
	const deleteTagFn = async (tag) => {
		await deleteTag(tag.id);
		await deleteTagFromSubs(tag.name);
		fetchTags();
	};
	const fetchTags = () => {
		getTags().then((rows) => setTags(rows));
	};
	useEffect(fetchTags, []);
	if (activeUser.status === "Unapproved") {
		return (
			<div className="main-container">
				You have not been approved for this content. Contact the admin
				for more information.
			</div>
		);
	}
	return (
		<Fragment>
			<div className="main-container">
				<div className="header-container">
					<div className="title">
						<h2>Tag Table</h2>
					</div>
					<div className="filters tag-create">
						<input
							id="tag-create"
							placeholder="Tag Name"
							value={tagToCreate}
							onChange={(e) => setTagToCreate(e.target.value)}
						/>
						<Button onClick={createTagFn}>Create</Button>
					</div>
				</div>
				<Card style={{ width: "100%" }}>
					<Card.Body>
						<Table
							style={{ width: "100%" }}
							striped
							bordered
							hover
							responsive
						>
							<thead>
								<tr>
									<th>Tag Name</th>
									<th>Creation Date</th>
									<th># Clips Tagged</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{tags.map((tag, i) => {
									return (
										<tr key={i}>
											<td>{tag.name}</td>
											<td>{tag.created_on}</td>
											<td>{tag.clips_tagged}</td>
											<td>
												<Button
													onClick={() => {
														setShow(true);
														setTagToDelete(tag);
													}}
													variant="danger"
												>
													Delete
												</Button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</div>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure?</Modal.Title>
				</Modal.Header>
				<Modal.Body>Deleting this tag will be permanent.</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Close
					</Button>
					<Button
						variant="danger"
						onClick={() => {
							deleteTagFn(tagToDelete);
							setShow(false);
						}}
					>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default TagTable;
