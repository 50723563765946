import axios from "axios";

export const getTags = () => {
	return axios.get("/api/tags/").then(res => res.data);
};

export const getActiveTags = id => {
	return axios.get(`/api/tags/active/${id}`).then(res => res.data);
};

export const createTag = name => {
	return axios.post("/api/tags/create", { name }).then(res => res.data);
};

export const deleteTag = id => {
	return axios.delete(`/api/tags/delete/${id}`).then(res => res.data);
};

export const deleteTagFromSubs = name => {
	return axios.put("/api/tags/delete-all", { name }).then(res => res.data);
};

export const updateSubTags = (tags, activeSubmission) => {
	return axios
		.put("/api/tags/update", { tags, activeSubmission })
		.then(res => res.data);
};
