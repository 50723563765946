import "./SiteNav.css";
import React from "react";
import { handleLogOut } from "../services/auth-requests";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import yoon from "../images/logo.png";
import { Link } from "react-router-dom";

const SiteNav = ({ activeUser }) => {
	const logOut = () => {
		handleLogOut().then((res) => {
			console.log(res);
			if (res === "success") {
				window.location.href = "/";
			}
		});
	};
	return (
		<Navbar bg="dark" variant="dark" expand="lg">
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				{activeUser.status !== "Unapproved" &&
				activeUser.status !== "Referrer" ? (
					<Nav className="mr-auto">
						<Link to="/">
							<div className="logo-cont">
								<img src={yoon} alt="yoon logo" />
							</div>
						</Link>

						<NavDropdown title="Queues" id="basic-nav-dropdown">
							<NavDropdown.Item href="/">
								User Submissions
							</NavDropdown.Item>
							<NavDropdown.Item href="/approved-cid">
								Approved Assets (CID)
							</NavDropdown.Item>
							<NavDropdown.Item href="/approved">
								Approved Assets (No CID)
							</NavDropdown.Item>
							<NavDropdown.Item href="/rejected">
								Rejected
							</NavDropdown.Item>
							<NavDropdown.Item href="/all-assets">
								All Assets
							</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link href="/users">User Table</Nav.Link>
						<NavDropdown title="Youtube Partners">
							<NavDropdown.Item href="/partners">
								Pending
							</NavDropdown.Item>
							<NavDropdown.Item href="/partners/approved">
								Approved
							</NavDropdown.Item>
							<NavDropdown.Item href="/partners/rejected">
								Rejected
							</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link href="/tags">Tags</Nav.Link>
						{activeUser.status === "Super Admin" ? (
							<Nav.Link href="/admin-dashboard">
								Admin Dashboard
							</Nav.Link>
						) : null}
						{activeUser.status === "Super Admin" ? (
							<Nav.Link href="/admin-activity-table">
								Admin Activity Table
							</Nav.Link>
						) : null}
					</Nav>
				) : null}
				<Nav className="ml-auto">
					<Button variant="outline-light" onClick={logOut}>
						Sign Out
					</Button>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default SiteNav;
